import { createHttpEndpoint } from '../../utils'

import type {
  GetPasswordResetCheckTokenRequest,
  PostPasswordResetCompleteFlowRequest,
  PostPasswordResetCompleteFlowResponse,
  PostPasswordResetStartFlowRequest,
} from './types'

/**
 * Start a password reset flow within the authzn stack
 */
export const postPasswordResetStartFlow = createHttpEndpoint<
  void,
  PostPasswordResetStartFlowRequest
>({
  method: 'POST',
  operationId: 'startPasswordResetFlow',
  path: '/auth/password-reset/start-flow',
})

/**
 * Check the validity of the password reset token
 */
export const checkPasswordResetToken = createHttpEndpoint<
  void,
  GetPasswordResetCheckTokenRequest
>({
  method: 'POST',
  path: '/auth/password-reset/check-token',
  operationId: 'checkPasswordResetToken',
})

/**
 * Submit the new passwords to the password reset flow
 */
export const submitPasswordResetFlow = createHttpEndpoint<
  PostPasswordResetCompleteFlowResponse,
  PostPasswordResetCompleteFlowRequest
>({
  method: 'POST',
  path: '/auth/password-reset/complete-flow',
  operationId: 'submitPasswordResetFlow',
})
